import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { Card, Col, Row } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';

const ProductsGrid = () => {
  const data = useStaticQuery(graphql`
    {
      allObchodJson {
        edges {
          node {
            title
            price
            page
            img
          }
        }
      }
    }
  `);
  return (
    <section className="menu-bg-limonady">
      <Container>
        <Row className="pb-5">
          <Col md={12} className="products-line">
            <h1 className="pt-5">ZÁKUSKY / DEZERTY</h1>
          </Col>

          {data.allObchodJson.edges.map((i) => (
            <Col md={3} className="pt-5 trans">
              <Link to={`/obchod/${i.node.page}`}>
                <Card className="products-card mb-4">
                  <Card.Img
                    className="products-img"
                    variant="top"
                    src={i.node.img}
                    alt={i.node.page}
                  />
                  <Card.Body>
                    <Card.Title style={{ textAlign: 'center' }}>
                      <strong>{i.node.title}</strong>
                    </Card.Title>
                    <Card.Text style={{ textAlign: 'center' }}>Cena od: {i.node.price}€</Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default ProductsGrid;
