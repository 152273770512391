import React from 'react';
import ProductsGrid from '../components/productsGrid';
import Layout from '../components/layout';
import SEO from '../components/seo';

const Obchod = () => (
  <Layout>
    <SEO
      title="ZÁKUSKY / DEZERTY"
      description="Naše produkty, ako koláče, zákusky a dezerty. Ponúkame raw ale aj pečené varianty, obsahujúce ale aj neobsahujúce lepok."
    />
    <ProductsGrid />
  </Layout>
);

export default Obchod;
